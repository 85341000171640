import { ShaderLib, ShaderMaterial, Texture, UniformsUtils } from "three";
import frag from "../Shaders/FloorPlanTile.frag";
import vert from "../Shaders/FloorPlanTile.vert";
import { initShaderChunk } from "../Shaders/InitShaderChunk";
import { makeUniform } from "./Uniforms";

/**
 * The transparency type of the floor plan background.
 */
export enum FloorPlanBackgroundTransparency {
	/** No transparent */
	No = 0,

	/** Invert luminance to set alpha (white -> 0.0, black -> 1.0) */
	Alpha = 1,

	/** Apply luminance threshold, alpha = 1 to pixels with luminance < 0.75 */
	Threshold = 2,
}

/**
 * The material used to render a floor plan.
 * This is just an extension of MeshBasicMaterial,
 */
export class FloorPlanMaterial extends ShaderMaterial {
	override vertexShader = vert;
	override fragmentShader = frag;

	override uniforms = UniformsUtils.merge([
		ShaderLib.basic.uniforms,
		{
			uBackgroundTransparent: makeUniform(0),
		},
	]);

	isMeshBasicMaterial = true;

	/**
	 * Create a new floor plan material with a given texture
	 *
	 * @param map The texture created from floor plan bitmap
	 * @param backgroundTransparent The transparency of the background of the floor plan
	 */
	constructor(map: Texture, backgroundTransparent: FloorPlanBackgroundTransparency) {
		super();
		initShaderChunk();
		this.uniforms.map.value = map;
		this.uniforms.uBackgroundTransparent.value = backgroundTransparent;
		this.transparent = true;
	}
}
