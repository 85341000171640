import { GUID } from "@faro-lotv/foundation";
import { clearStore } from "@faro-lotv/project-source";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

/** The edit mode, defining which actions the user can take while editing. */
export enum RegistrationEditMode {
  /** Moving and rotating scans. */
  editScans = "editScans",
  /** Manually adding registration edges, which will _always_ be included in the cloud registration. */
  addConnection = "addConnection",
  /** Manually delete registration edges, which will _never_ be included in the cloud registration. */
  deleteConnection = "deleteConnection",
}

type DataPreparationUiState = {
  /** The selected entity id in the scan-tree */
  selectedEntityId?: GUID;

  /** The hovered entity id in the scan-tree */
  hoveredEntityId?: GUID;

  /** The currently active edit mode. */
  editMode?: RegistrationEditMode;
};

export const DATA_PREPARATION_UI_INITIAL_STATE: Readonly<DataPreparationUiState> =
  Object.freeze({});

const dataPreparationUiSlice = createSlice({
  initialState: DATA_PREPARATION_UI_INITIAL_STATE,
  name: "data-preparation-ui",

  reducers: {
    /**
     * @param state The current application state
     * @param action The entity id to select
     */
    setSelectedEntityId(state, action: PayloadAction<GUID | undefined>) {
      state.selectedEntityId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The entity id that is currently hovered
     */
    setHoveredEntityId(state, action: PayloadAction<GUID | undefined>) {
      state.hoveredEntityId = action.payload;
    },

    /**
     * @param state The current application state
     * @param action The entity id that is should be unset if it is currently hovered
     */
    unsetHoveredEntityId(state, action: PayloadAction<GUID | undefined>) {
      if (state.hoveredEntityId === action.payload) {
        state.hoveredEntityId = undefined;
      }
    },

    /**
     * Enable editing and switch to the default editing mode.
     *
     * @param state The current application state
     */
    enableDefaultEditMode(state) {
      state.editMode = RegistrationEditMode.editScans;
    },

    /**
     * Disable any active edit mode, making the view read-only again.
     *
     * @param state The current application state
     */
    disableEditMode(state) {
      state.editMode = undefined;
    },

    /**
     * @param state The current application state
     * @param action The new active edit mode
     */
    setEditMode(state, action: PayloadAction<RegistrationEditMode>) {
      state.editMode = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => DATA_PREPARATION_UI_INITIAL_STATE);
  },
});

export const {
  setSelectedEntityId,
  setHoveredEntityId,
  unsetHoveredEntityId,
  enableDefaultEditMode,
  disableEditMode,
  setEditMode,
} = dataPreparationUiSlice.actions;

export const dataPreparationUiReducer = dataPreparationUiSlice.reducer;
