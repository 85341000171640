import {
  EventType,
  SelectColoredAnalysisColorScaleEventProperties,
  SelectColoredAnalysisToleranceEventProperties,
} from "@/analytics/analytics-events";
import {
  PointCloudAnalysis,
  PointCloudAnalysisColormap,
  setAnalysisColormap,
  setAnalysisTolerance,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import {
  areColormapsSame,
  isColormapPresetName,
  PointCloudAnalysisColormapPreset,
  pointCloudAnalysisColormapPresets,
} from "@/utils/colormap-analysis-utils";
import {
  convertUnit,
  MeasurementUnits,
} from "@faro-lotv/app-component-toolbox";
import {
  ColorBar,
  ColorsWithRatio,
  Dropdown,
  FaroText,
  neutral,
  TextField,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { SupportedUnitsOfMeasure } from "@faro-lotv/ielement-types";
import { Box, Grid, Stack } from "@mui/material";
import { debounce } from "es-toolkit";
import { useCallback, useEffect, useMemo, useState } from "react";
import { ColorManagement } from "three";

const colormapOptions = [
  {
    key: PointCloudAnalysisColormapPreset.rainbow,
    label: "Rainbow",
    value: PointCloudAnalysisColormapPreset.rainbow,
  },
  {
    key: PointCloudAnalysisColormapPreset.grayscale,
    label: "Grayscale",
    value: PointCloudAnalysisColormapPreset.grayscale,
  },
  {
    key: PointCloudAnalysisColormapPreset.blueGreenRed,
    label: "Blue-green-red",
    value: PointCloudAnalysisColormapPreset.blueGreenRed,
  },
  {
    key: PointCloudAnalysisColormapPreset.redGreenRed,
    label: "Red-green-red",
    value: PointCloudAnalysisColormapPreset.redGreenRed,
  },
];

type ColormapOptionsProps = {
  /** Active analysis object to be modified. */
  analysis: PointCloudAnalysis;

  /** Current selected unit of measure used to display the distances */
  unitOfMeasure: SupportedUnitsOfMeasure;
};

/** @returns Colormap options UI panel */
export function ColormapOptionsPanel({
  analysis,
  unitOfMeasure,
}: ColormapOptionsProps): JSX.Element {
  const dispatch = useAppDispatch();

  const [colormapName, setColormapName] = useState(() => {
    const initialName = Object.entries(pointCloudAnalysisColormapPresets).find(
      ([, value]) => areColormapsSame(value, analysis.colormap),
    );
    return initialName?.[0];
  });

  const computeColorBarColors = useCallback(
    (colormap: PointCloudAnalysisColormap): ColorsWithRatio => {
      // The whole colormap range is mapped to the middle portion of the color bar.
      // The space before and after the whole range is padding with the minimal and maximal deviation color.
      // This constant defines padding ratio of the space before and after the color map range.
      const COLOR_SCALE_PADDING = 0.15;
      // scale the colormap range to middle portion of the color bar, with padding on both sides
      const colorRange = 1.0 - 2 * COLOR_SCALE_PADDING;

      // Colors supplied to three.js need to be in the Linear-sRGB. Certain conversions (for hexadecimal
      // and CSS colors in sRGB) can be made automatically if the THREE.ColorManagement API is enabled.
      // see: https://threejs.org/docs/?q=color#manual/en/introduction/Color-management
      // Enable the ColorManagement make sure `getHexString` return the hexadecimal color in sRGB for CSS.
      // However, the global flag `ColorManagement.enabled` may be set to `false` by the application for
      // certain reason, so we need to save and restore it.
      const colorManagementEnabled = ColorManagement.enabled;
      ColorManagement.enabled = true;

      const colors: ColorsWithRatio = colormap.map((colorKey) => ({
        color: colorKey.color,
        ratio: colorKey.value * colorRange + COLOR_SCALE_PADDING,
      }));

      ColorManagement.enabled = colorManagementEnabled;

      return [
        {
          color: colors[0].color,
          ratio: 0.0,
        },
        ...colors,
        {
          color: colors[colors.length - 1].color,
          ratio: 1.0,
        },
      ];
    },
    [],
  );

  const [colorBarColors, setColorBarColors] = useState(() =>
    computeColorBarColors(analysis.colormap),
  );

  const changeColormap = useCallback(
    (newColormap: string) => {
      Analytics.track<SelectColoredAnalysisColorScaleEventProperties>(
        EventType.selectColoredAnalysisColorScale,
        {
          newValue: newColormap,
        },
      );

      setColormapName(newColormap);
      if (isColormapPresetName(newColormap)) {
        const colormap = pointCloudAnalysisColormapPresets[newColormap];
        setColorBarColors(computeColorBarColors(colormap));
        dispatch(
          setAnalysisColormap({
            analysisId: analysis.id,
            colormap,
          }),
        );
      }
    },
    [analysis.id, computeColorBarColors, dispatch],
  );

  const formatToleranceText = useCallback(
    () =>
      unitOfMeasure === "metric"
        ? convertUnit(
            analysis.tolerance,
            MeasurementUnits.meters,
            MeasurementUnits.millimeters,
          ).toFixed(0)
        : convertUnit(
            analysis.tolerance,
            MeasurementUnits.meters,
            MeasurementUnits.inches,
          ).toFixed(3),
    [analysis.tolerance, unitOfMeasure],
  );

  const [toleranceText, setToleranceText] = useState("");
  const [toleranceValue, setToleranceValue] = useState(0);
  const [toleranceChanged, setToleranceChanged] = useState(false);

  useEffect(() => {
    // reset toleranceChanged flag when unit of measure is changed
    setToleranceChanged(false);
  }, [unitOfMeasure]);

  useEffect(() => {
    // avoid reset text when tolerance is changed from the textbox
    if (!toleranceChanged) {
      setToleranceText(formatToleranceText());
    }
  }, [formatToleranceText, toleranceChanged]);

  // We are not interested in temporary changes of tolerance while editing. Wait 1 sec before report last update
  const [trackDebouncedTolerance] = useState(() => {
    return debounce((tolerance: number) => {
      Analytics.track<SelectColoredAnalysisToleranceEventProperties>(
        EventType.selectColoredAnalysisTolerance,
        {
          newTolerance: tolerance,
        },
      );
    }, 1000);
  });

  useEffect(() => {
    trackDebouncedTolerance(toleranceValue);
  }, [toleranceValue, trackDebouncedTolerance]);

  const [toleranceError, setToleranceError] = useState("");
  const changeTolerance = useCallback(
    (newText: string) => {
      setToleranceText(newText);
      const newTolerance = Number(newText);
      if (isNaN(newTolerance)) {
        setToleranceError("Invalid number");
      } else {
        const tolerance = Math.abs(
          unitOfMeasure === "metric"
            ? convertUnit(
                newTolerance,
                MeasurementUnits.millimeters,
                MeasurementUnits.meters,
              )
            : convertUnit(
                newTolerance,
                MeasurementUnits.inches,
                MeasurementUnits.meters,
              ),
        );

        setToleranceValue(tolerance);

        setToleranceError("");
        setToleranceChanged(true);
        dispatch(setAnalysisTolerance({ analysisId: analysis.id, tolerance }));
      }
    },
    [analysis.id, dispatch, unitOfMeasure],
  );

  const { minColorLabel, maxColorLabel } = useMemo(() => {
    const toleranceText = formatToleranceText();
    const unitText = unitOfMeasure === "metric" ? "mm" : "in";
    const minColorLabel = `-${toleranceText}${unitText}`;
    const maxColorLabel = `${toleranceText}${unitText}`;
    return { minColorLabel, maxColorLabel };
  }, [formatToleranceText, unitOfMeasure]);

  return (
    <Grid container spacing={1} alignItems="center" sx={{ width: "400px" }}>
      <Grid item xs={5}>
        <Dropdown
          options={colormapOptions}
          value={colormapName}
          dark
          onChange={(e) => changeColormap(e.target.value)}
        />
      </Grid>
      <Grid item xs={7}>
        <ColorBar colors={colorBarColors} />
      </Grid>
      <Grid item xs={2}>
        <FaroText variant="labelL" sx={{ color: neutral[0] }}>
          Tolerance
        </FaroText>
      </Grid>
      <Grid item xs={2}>
        <TextField
          fullWidth
          dark
          text={toleranceText}
          error={toleranceError}
          onTextChanged={changeTolerance}
        />
      </Grid>
      <Grid item xs={1}>
        <FaroText variant="labelL" sx={{ color: neutral[0] }}>
          {unitOfMeasure === "metric" ? "mm" : "in"}
        </FaroText>
      </Grid>
      <Grid item xs={7}>
        <ColorScaleLineIndicator
          minColorLabel={minColorLabel}
          maxColorLabel={maxColorLabel}
        />
      </Grid>
    </Grid>
  );
}

type ColorScaleLineIndicatorProps = {
  /** Min color deviation label */
  minColorLabel: string;

  /** Max color deviation label */
  maxColorLabel: string;
};

/**
 * Color scale line indicator of the min and max color deviations.
 *      min           max
 *   ----|------+------|----
 * The positions of the vertical lines match the color bar ratios.
 *
 * @returns Color scale line indicator
 */
function ColorScaleLineIndicator({
  minColorLabel,
  maxColorLabel,
}: ColorScaleLineIndicatorProps): JSX.Element {
  return (
    <Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-between">
        <Stack direction="row" width="30%" justifyContent="center">
          <FaroText variant="bodyS" color={neutral[0]}>
            {minColorLabel}
          </FaroText>
        </Stack>
        <Stack direction="row" width="30%" justifyContent="center">
          <FaroText variant="bodyS" color={neutral[0]}>
            {maxColorLabel}
          </FaroText>
        </Stack>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="center">
        <Box component="div" width="15%" height="1px" bgcolor={neutral[0]} />
        <Box component="div" width="1px" height="10px" bgcolor={neutral[0]} />
        <Box component="div" width="35%" height="1px" bgcolor={neutral[0]} />
        <Box component="div" width="1px" height="8px" bgcolor={neutral[0]} />
        <Box component="div" width="35%" height="1px" bgcolor={neutral[0]} />
        <Box component="div" width="1px" height="10px" bgcolor={neutral[0]} />
        <Box component="div" width="15%" height="1px" bgcolor={neutral[0]} />
      </Stack>
    </Stack>
  );
}
