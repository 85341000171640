import {
  GUID,
  isOptionalPropMissing,
  Optional,
  Optionality,
  PropOptional,
  PropRequired,
  validateArrayOf,
  validateEnumValue,
  validateNotNullishObject,
  validateOfType,
  validatePrimitive,
} from "@faro-lotv/foundation";
import {
  IQuat,
  ISOTimeString,
  IVec3,
  UserId,
  validateQuat,
  validateVec3,
} from "@faro-lotv/ielement-types";
import { DataSetLocalPose, isDataSetLocalPose } from "./project-api-types";

/** Possible types of a capture tree entity */
export enum CaptureTreeEntityType {
  cluster = "Cluster",
  focusScan = "FocusScan",
  orbisScan = "OrbisScan",
  pCloudUploadScan = "PCloudUploadScan",
  elsScan = "ElsScan",
  root = "Root",
}

/** Possible types of a capture tree point cloud */
export enum CaptureTreePointCloudType {
  cpe = "Cpe",
  e57 = "E57",
  laz = "Laz",
  geoslam = "GeoSlam",
  pCloud = "PCloud",
  flsRaw = "FlsRaw",
  flsProcessed = "FlsProcessed",
  elsRaw = "ElsRaw",
  elsProcessed = "ElsProcessed",
}

export type CaptureTreePointCloudProperties = {
  externalId?: string;
  /** The id of the capture tree point cloud */
  id: GUID;
  /**  The type of the capture tree point cloud*/
  type: CaptureTreePointCloudType;
  /** The URI to the capture tree point cloud */
  uri: string | null;
  /**  The md5 hash of the point cloud file */
  md5Hash: string | null;
  /**  The file size of the capture tree point cloud */
  fileSize: number | null;
  /**  The file name of the capture tree point cloud */
  fileName: string | null;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid CaptureTreePointCloudProperties
 */
export function isCaptureTreePointCloudProperties(
  data: unknown,
): data is CaptureTreePointCloudProperties {
  return (
    validateNotNullishObject<CaptureTreePointCloudProperties>(
      data,
      "CaptureTreePointCloudProperties",
    ) &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "externalId", "string", PropOptional) &&
    validatePrimitive(data, "uri", "string", PropOptional) &&
    validatePrimitive(data, "md5Hash", "string", PropOptional) &&
    validatePrimitive(data, "fileName", "string", PropOptional) &&
    validatePrimitive(data, "fileSize", "number", PropOptional) &&
    validateEnumValue(data.type, CaptureTreePointCloudType)
  );
}

export type CaptureTreeEntity = {
  /** The unique global identifier of the CaptureTreeEntity */
  id: GUID;
  /**  The id of the parent entity */
  parentId: GUID | null;
  /** The name of the entity */
  name: string;
  /**  The pose of the entity */
  pose: DataSetLocalPose;
  /** The type of the entity */
  type: CaptureTreeEntityType;
  /** The ID of the user who created the entity */
  createdBy: UserId;
  /** The date time when the entity was created */
  createdAt: ISOTimeString;
  /** The ID of the user who last patched the entity */
  lastPatchedBy: UserId;
  /** The date time when the entity was last patched */
  lastPatchedAt: ISOTimeString;
  /** An array of point cloud representations of this entity */
  pointClouds?: CaptureTreePointCloudProperties[];
};

/** The status of an object in a revision */
export enum RevisionStatus {
  /** The object was included from the main revision when this revision was created. */
  initialized = "Initialized",
  /** The object was added in this revision. */
  added = "Added",
  /** The object existed in the main revision, but has been modified in this revision. */
  modified = "Modified",
  /**
   * The object was deleted implicitly:
   *
   * - The object existed in the main revision.
   * - The object was included in this revision, but never modified.
   */
  deleted = "Deleted",
  /**
   * The object was deleted explicitly (previously existed):
   *
   * - The object existed in the main revision.
   * - The object was explicitly deleted later in this revision.
   */
  deletedExplicitly = "DeletedExplicitly",
  /**
   * The object was deleted explicitly (newly added):
   *
   * - The object was added in this revision.
   * - The object was explicitly deleted later in this revision.
   */
  addedAndDeleted = "AddedAndDeleted",
}

/**
 * @param status the status of the revision object.
 * @returns whether the status indicates that the object has been deleted.
 */
export function isDeletedRevisionStatus(status: RevisionStatus): boolean {
  return (
    status === RevisionStatus.deleted ||
    status === RevisionStatus.deletedExplicitly ||
    status === RevisionStatus.addedAndDeleted
  );
}

export type CaptureTreeEntityRevision = CaptureTreeEntity & {
  /** The status of the entity revision */
  status: RevisionStatus;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid CaptureTreeEntity
 */
export function isCaptureTreeEntity(data: unknown): data is CaptureTreeEntity {
  return (
    validateNotNullishObject<CaptureTreeEntity>(data, "CaptureTreeEntity") &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "name", "string") &&
    validatePrimitive(data, "lastPatchedBy", "string") &&
    validatePrimitive(data, "createdAt", "string") &&
    validatePrimitive(data, "createdBy", "string") &&
    validatePrimitive(data, "lastPatchedAt", "string") &&
    validateEnumValue(data.type, CaptureTreeEntityType) &&
    isDataSetLocalPose(data.pose) &&
    validateArrayOf({
      object: data,
      prop: "pointClouds",
      elementGuard: isCaptureTreePointCloudProperties,
      optionality: PropOptional,
    })
  );
}

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid CaptureTreeEntity
 */
export function isCaptureTreeEntityRevision(
  data: unknown,
): data is CaptureTreeEntityRevision {
  return (
    validateNotNullishObject<CaptureTreeEntityRevision>(
      data,
      "CaptureTreeEntityRevision",
    ) &&
    validateEnumValue(data.status, RevisionStatus) &&
    isCaptureTreeEntity(data)
  );
}

/** The state of a registration revision */
export enum RegistrationState {
  started = "Started",
  userModified = "UserModified",
  cloudRegistrationStarted = "CloudRegistrationStarted",
  registered = "Registered",
  merged = "Merged",
  canceled = "Canceled",
}

/**
 * The list of possible clients that create a registration revision.
 * Keep in sync with: SphereProjectAPI/src/app/HoloModel/Types/CaptureApi/RegistrationRevision.cs --> CaptureApiClient.
 */
export enum CaptureApiClient {
  stream = "Stream",
  scene = "Scene",
  registrationBackend = "RegistrationBackend",
  dashboard = "Dashboard",
  explorer = "Explorer",
}

export type RegistrationRevision = {
  /** The unique global identifier of the RegistrationRevision */
  id: GUID;
  /** The ID of the user who created the revision */
  createdBy: UserId;
  /** The date time when the revision was created */
  createdAt: UserId;
  /** The ID of the user who last modified the revision */
  modifiedBy: UserId;
  /** The date time when the revision was last modified */
  modifiedAt: UserId;
  /** The projectId of the registration revision */
  projectId: GUID;
  /** The state of the registration revision */
  state: RegistrationState;
  /** The client that created the registration revision */
  createdByClient: CaptureApiClient | null;
  /** The URI of the registration report */
  reportUri: string | null;
};

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid RegistrationRevision
 */
export function isRegistrationRevision(
  data: unknown,
): data is RegistrationRevision {
  return (
    validateNotNullishObject<RegistrationRevision>(
      data,
      "RegistrationRevision",
    ) &&
    validateEnumValue(data.state, RegistrationState) &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "projectId", "string") &&
    validatePrimitive(data, "modifiedAt", "string") &&
    validatePrimitive(data, "createdAt", "string") &&
    validatePrimitive(data, "createdBy", "string") &&
    validatePrimitive(data, "modifiedBy", "string")
  );
}

/** The type of a registration edge */
export enum RegistrationEdgeType {
  slam = "Slam",
  preReg = "PreReg",
  local = "Local",
  global = "Global",
}

/** The type of calculation for the histogram */
export enum DistanceType {
  /** The distances are between the points and a plane. */
  point2Plane = "Point2Plane",
  /** The distances are between two points. */
  point2Point = "Point2Point",
}

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `DistanceType`, else `false`.
 */
export function validateDistanceType(value: unknown): value is DistanceType {
  return (
    typeof value === "string" &&
    Object.values<string>(DistanceType).includes(value)
  );
}

/** The base type for a registration edge */
export type RegistrationEdgeBase = {
  /** The unique global identifier of the RegistrationEdge */
  id: GUID;
  /** The ID of the user who created the edge */
  createdBy: UserId;
  /** The date time when the edge was created */
  createdAt: UserId;
  /** The ID of the user who last patched the registration edge. */
  lastPatchedBy: UserId;
  /** The date time when the registration edge was last patched. */
  lastPatchedAt: ISOTimeString;
  /** The type of registration performed on this edge. */
  type: RegistrationEdgeType;
  /** The ID of the source element for this registration. */
  sourceId: GUID;
  /** The ID of the target element for this registration. */
  targetId: GUID;
  /** Additional data (the type is not enforced by the API currently) */
  data: unknown;
};

/**
 *
 * @param data the object returned by the API backend
 * @returns true if the data is a valid RegistrationEdgeBase
 */
export function isRegistrationEdgeBase(
  data: unknown,
): data is RegistrationEdgeBase {
  return (
    validateNotNullishObject<RegistrationEdge>(data, "RegistrationEdgeBase") &&
    validatePrimitive(data, "id", "string") &&
    validatePrimitive(data, "createdBy", "string") &&
    validatePrimitive(data, "createdAt", "string") &&
    validatePrimitive(data, "lastPatchedBy", "string") &&
    validatePrimitive(data, "lastPatchedAt", "string") &&
    validateEnumValue(data.type, RegistrationEdgeType) &&
    validatePrimitive(data, "sourceId", "string") &&
    validatePrimitive(data, "targetId", "string")
  );
}

/** Data commonly included in registration edges. */
export type CommonEdgeData = {
  /** The json revision of the registration edge schema. */
  jsonRevision: number;
  /** The transformation data for the registration. */
  transformation?: Transformation;
  /** The metrics for the registration. */
  metrics?: RegistrationMetrics;
  /** The algorithm used for the registration. */
  algorithm?: Algorithm;
  /** The metadata for the registration. */
  metadata?: RegistrationMetadata;
};

/**
 * @param data the object returned by the API backend
 * @returns `true`, if `data` is a valid `CommonEdgeData`, else `false`.
 */
export function isCommonEdgeData(data: unknown): data is CommonEdgeData {
  return (
    validateNotNullishObject<CommonEdgeData>(data, "CommonEdgeData") &&
    validatePrimitive(data, "jsonRevision", "number") &&
    validateOfType(
      data,
      "transformation",
      validateTransformation,
      PropOptional,
    ) &&
    validateOfType(
      data,
      "metrics",
      validateRegistrationMetrics,
      PropOptional,
    ) &&
    validateOfType(data, "algorithm", validateAlgorithm, PropOptional) &&
    validateOfType(data, "metadata", validateRegistrationMetadata, PropOptional)
  );
}

/** The data from the RegistrationEdgeBase, with its needed RegistrationMetrics. */
export type LocalRegistrationEdge = RegistrationEdgeBase & {
  /** The type of registration performed on this edge. */
  type: RegistrationEdgeType.local;
  /** The data specific to local registration edges. */
  data: CommonEdgeData;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `LocalRegistrationEdge`, else `false`.
 */
export function isLocalRegistrationEdge(
  value: unknown,
): value is LocalRegistrationEdge {
  return (
    validateNotNullishObject<RegistrationEdgeBase>(
      value,
      "LocalRegistrationEdge",
    ) &&
    isRegistrationEdgeBase(value) &&
    value.type === RegistrationEdgeType.local &&
    validateOfType(value, "data", isCommonEdgeData)
  );
}

/** The data from the RegistrationEdgeBase, for an already run registration. */
export type GlobalRegistrationEdge = RegistrationEdgeBase & {
  /** The type of registration performed on this edge. */
  type: RegistrationEdgeType.global;
  /** The data specific to global registration edges. */
  data: CommonEdgeData;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `GlobalRegistrationEdge`, else `false`.
 */
export function isGlobalRegistrationEdge(
  value: unknown,
): value is GlobalRegistrationEdge {
  return (
    validateNotNullishObject<RegistrationEdgeBase>(
      value,
      "GlobalRegistrationEdge",
    ) &&
    isRegistrationEdgeBase(value) &&
    value.type === RegistrationEdgeType.global &&
    validateOfType(value, "data", isCommonEdgeData)
  );
}

/** The data from the RegistrationEdgeBase, depicting a PreReg registration. */
export type PreRegistrationEdge = RegistrationEdgeBase & {
  /** The type of registration performed on this edge. */
  type: RegistrationEdgeType.preReg;
  /** The data specific to pre-registration edges. */
  data?: CommonEdgeData | null;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `PreRegistrationEdge`, else `false`.
 */
export function isPreRegistrationEdge(
  value: unknown,
): value is PreRegistrationEdge {
  return (
    validateNotNullishObject<RegistrationEdgeBase>(
      value,
      "PreRegistrationEdge",
    ) &&
    isRegistrationEdgeBase(value) &&
    value.type === RegistrationEdgeType.preReg &&
    validateOfType(value, "data", isCommonEdgeData, PropOptional)
  );
}

/** The data from the RegistrationEdgeBase, depicting a Slam registration. */
export type SlamRegistrationEdge = RegistrationEdgeBase & {
  /** The type of registration performed on this edge. */
  type: RegistrationEdgeType.slam;
  /** The data specific to SLAM registration edges. */
  data?: CommonEdgeData | null;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `SlamRegistrationEdge`, else `false`.
 */
export function isSlamRegistrationEdge(
  value: unknown,
): value is SlamRegistrationEdge {
  return (
    validateNotNullishObject<RegistrationEdgeBase>(
      value,
      "SlamRegistrationEdge",
    ) &&
    isRegistrationEdgeBase(value) &&
    value.type === RegistrationEdgeType.slam &&
    validateOfType(value, "data", isCommonEdgeData, PropOptional)
  );
}

/** Union type for all registration edge variants */
export type RegistrationEdge =
  | LocalRegistrationEdge
  | GlobalRegistrationEdge
  | PreRegistrationEdge
  | SlamRegistrationEdge;

/**
 * @param data the object returned by the API backend
 * @returns true if the data is a valid RegistrationEdge
 */
export function isRegistrationEdge(data: unknown): data is RegistrationEdge {
  return (
    isLocalRegistrationEdge(data) ||
    isGlobalRegistrationEdge(data) ||
    isPreRegistrationEdge(data) ||
    isSlamRegistrationEdge(data)
  );
}

/**
 * Additional information about the algorithm which was used for the registration.
 * The data is not tightly validated, as it’s considered optional information with
 * the main purpose being reproducibility and debugging.
 */
export type Algorithm = {
  identifier: string;
  parameters?: Record<string, unknown>;
  algorithmSpecificMetrics?: Record<string, unknown>;
};

/**
 * @param value The value to check for its type.
 * @param optionality to define if this property can be null
 * @returns `true`, if `value` is a valid `Algorithm`, else `false`.
 */
export function validateAlgorithm(
  value: unknown,
  optionality: Optionality = PropRequired,
): value is Algorithm {
  // validating against optionality
  if (isOptionalPropMissing(value, optionality)) {
    return true;
  }

  return (
    validateNotNullishObject<Algorithm>(value, "Algorithm") &&
    validatePrimitive(value, "identifier", "string") &&
    (value.parameters === undefined ||
      (typeof value.parameters === "object" &&
        !(value.parameters instanceof Array))) &&
    (value.algorithmSpecificMetrics === undefined ||
      (typeof value.algorithmSpecificMetrics === "object" &&
        !(value.algorithmSpecificMetrics instanceof Array)))
  );
}

/**
 * The transformation data type.
 */
export type Transformation = {
  pos: IVec3;
  rot: IQuat;
};

/**
 * @param value The value to check for its type.
 * @returns `true`, if `value` is a valid `Transformation`, else `false`.
 */
export function validateTransformation(
  value: unknown,
): value is Transformation {
  return (
    validateNotNullishObject<Transformation>(value, "Transformation") &&
    validateOfType(value, "pos", validateVec3) &&
    validateOfType(value, "rot", validateQuat)
  );
}

/**
 * Metadata for the registration.
 * Can be used for debugging or to store additional information.
 */
export type RegistrationMetadata = {
  /**
   * The time it took to execute the registration in milliseconds.
   */
  executionTime?: number;
  /**
   * The number of cascades the algorithm did to increase the precision.
   */
  numberOfCascades?: number | null;
  /** Extra properties that may be registration or revision specific */
  [key: string]: unknown;
};

/**
 * @param value The value to check for its type.
 * @param optionality to define if this property can be null
 * @returns `true`, if `value` is a valid `RegistrationMetadata`, else `false`.
 */
export function validateRegistrationMetadata(
  value: unknown,
  optionality: Optionality = PropRequired,
): value is RegistrationMetadata {
  // validating against optionality
  if (isOptionalPropMissing(value, optionality)) {
    return true;
  }

  return (
    validateNotNullishObject<RegistrationMetadata>(
      value,
      "RegistrationMetadata",
    ) &&
    (value.executionTime === undefined ||
      typeof value.executionTime === "number") &&
    (value.numberOfCascades === undefined ||
      value.numberOfCascades === null ||
      typeof value.numberOfCascades === "number")
  );
}

export type RlyHistogram = {
  /**
   * The histogram bins as array.
   *
   * Each entry determines the number of points in each bin.
   */
  bins: number[];

  /** The histogram resolution aka width of each bin. */
  resolution: number;

  /** The bounding box of the histogram, in meters. Empty if histogram is empty. */
  limits: [number, number] | [];

  /** The median value of the histogram data. */
  median: number;

  /** Whether we use Point2Plane or Point2Point distance. */
  distanceType: DistanceType;
};

/**
 * @param value The value to check for its type.
 * @param optionality to define if this property can be null
 * @returns `true`, if `value` is a valid `RlyHistogram`, else `false`.
 */
export function validateRlyHistogram(
  value: unknown,
  optionality: Optionality = PropRequired,
): value is RlyHistogram {
  // validating against optionality
  if (isOptionalPropMissing(value, optionality)) {
    return true;
  }

  if (!validateNotNullishObject<RlyHistogram>(value, "RlyHistogram")) {
    return false;
  }

  return (
    validateArrayOf({
      object: value,
      prop: "bins",
      elementGuard: (x) => typeof x === "number",
    }) &&
    validatePrimitive(value, "resolution", "number") &&
    (validateArrayOf({
      object: value,
      prop: "limits",
      size: 2,
      elementGuard: (x) => typeof x === "number",
    }) ||
      validateArrayOf({
        object: value,
        prop: "limits",
        size: 0,
        elementGuard: (x) => typeof x === "number",
      })) &&
    validatePrimitive(value, "median", "number") &&
    validateEnumValue(value.distanceType, DistanceType)
  );
}

/**
 * An optional list of metrics, as they are calculated from the RLY Metric calculation.
 */
export type RegistrationMetrics = {
  /** Distribution of point distances over all points in the registered point clouds. */
  rlyHistogram?: RlyHistogram | null;

  /** Clip Chamfer metric. */
  clipChamferDistance?: number | null;

  /** Overlap ratio between the point clouds. */
  overlap?: number | null;

  /** FScore of the registration. */
  fscore?: number | null;

  /** Number of cascades the algorithm did do increase the precision */
  numberOfCascades?: number | null;
};

/**
 * @param value The property to check for its type.
 * @param optionality The parameter to define if this property can be null
 * @returns `true`, if `value` are valid `RegistrationMetrics`, else `false`.
 */
export function validateRegistrationMetrics(
  value: unknown,
): value is RegistrationMetrics {
  return (
    validateNotNullishObject<RegistrationMetrics>(
      value,
      "RegistrationMetrics",
    ) &&
    // rlyHistogram
    validateRlyHistogram(value.rlyHistogram, PropOptional) &&
    // clipChamferDistance
    validatePrimitive(value, "clipChamferDistance", "number", PropOptional) &&
    // overlap
    validatePrimitive(value, "overlap", "number", PropOptional) &&
    // fscore
    validatePrimitive(value, "fscore", "number", PropOptional) &&
    // numberOfCascades
    validatePrimitive(value, "numberOfCascades", "number", PropOptional)
  );
}

/** The registration edge with its RevisionStatus */
export type RegistrationEdgeRevision = RegistrationEdge & {
  status: RevisionStatus;
};

/**
 * @param obj The object to check for its type.
 * @returns a type guard for the registration edge revision with the given data type
 */
export function isRegistrationEdgeRevision(
  obj: unknown,
): obj is RegistrationEdgeRevision {
  // Validation order needs to be like this, because once `isRegistrationEdge` is `true`,
  // TS doesn't understand that there can be an extra `status` field
  return (
    validateNotNullishObject<RegistrationEdgeRevision>(
      obj,
      "RegistrationEdgeRevision",
    ) &&
    validateEnumValue(obj.status, RevisionStatus) &&
    isRegistrationEdge(obj)
  );
}

/** The entity types which correspond to a point cloud scan. */
export const revisionScanEntityTypes = [
  CaptureTreeEntityType.elsScan,
  CaptureTreeEntityType.focusScan,
  CaptureTreeEntityType.orbisScan,
  CaptureTreeEntityType.pCloudUploadScan,
] as const;

/** A revision entity corresponding to a point cloud scan. */
export type RevisionScanEntity = CaptureTreeEntityRevision & {
  type: (typeof revisionScanEntityTypes)[number];
};

/**
 * @param entity The revision entity to check.
 * @returns Whether the revision entity is a point cloud scan.
 */
export function isRevisionScanEntity(
  entity: CaptureTreeEntityRevision,
): entity is RevisionScanEntity {
  return Object.values<CaptureTreeEntityType>(revisionScanEntityTypes).includes(
    entity.type,
  );
}

export type UpdateRegistrationRevisionParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** The updated state of the registration */
  state: RegistrationState;

  /** The URI of the registration report */
  reportUri?: string | null;

  /** The project point cloud of this revision */
  projectPointCloud?: CaptureTreePointCloudProperties;
};

/** Params to set the pose of a new entity */
type CreateEntityPoseParam = Optional<DataSetLocalPose, "scale">;

/**
 * @param data to check
 * @returns true if it matches the CreateEntityPoseParam type
 */
export function isCreateEntityPoseParam(
  data: unknown,
): data is CreateEntityPoseParam {
  return (
    validateNotNullishObject(data, "CreateEntityPoseParam") &&
    validateOfType(data, "pos", validateVec3) &&
    validateOfType(data, "rot", validateQuat) &&
    validateOfType(data, "scale", validateVec3, PropOptional)
  );
}

/** Request body to create a root entity */
export type CreateOrUpdateRootEntityRequestBody = {
  /** The ID of the root entity, if it is being updated. */
  id?: GUID;

  /** The pose of the entity */
  pose: CreateEntityPoseParam;
};

/**
 * @param data to check
 * @returns true if it matches the CreateRootEntityRequestBody type
 */
export function isCreateRootEntityRequestBody(
  data: unknown,
): data is CreateOrUpdateRootEntityRequestBody {
  return (
    validateNotNullishObject(data, "CreateRootEntityRequestBody") &&
    validatePrimitive(data, "id", "string", PropOptional) &&
    isCreateEntityPoseParam(data.pose)
  );
}

/** Params for the request to create a root entity */
export type CreateRootEntityParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: CreateOrUpdateRootEntityRequestBody;
};

/** Params to create a cluster entity  */
export type CreateOrUpdateClusterEntityParams = {
  /** The ID of the cluster if it is being edited. */
  id?: GUID;

  /** The pose of the entity */
  pose: CreateEntityPoseParam;

  /** The id of the parent entity */
  parentId?: GUID | null;

  /** The name of the entity */
  name: string;
};

/**
 * @param data to check
 * @returns true if it matches the CreateClusterEntityParams type
 */
export function isCreateClusterEntityParams(
  data: unknown,
): data is CreateOrUpdateClusterEntityParams {
  return (
    validateNotNullishObject(data, "CreateClusterEntityParams") &&
    validatePrimitive(data, "id", "string", PropOptional) &&
    isCreateEntityPoseParam(data.pose) &&
    validatePrimitive(data, "parentId", "string", PropOptional) &&
    validatePrimitive(data, "name", "string")
  );
}

/** Request body to create clusters entities: array of cluster entity params */
type CreateClusterEntitiesRequestBody = CreateOrUpdateClusterEntityParams[];

/**
 * @param data to check
 * @returns true if it matches the CreateClusterEntitiesRequestBody type
 */
export function isCreateClusterEntitiesRequestBody(
  data: unknown,
): data is CreateClusterEntitiesRequestBody {
  const obj = { requestBody: data };

  return validateArrayOf({
    object: obj,
    prop: "requestBody",
    elementGuard: isCreateClusterEntityParams,
  });
}

/** Params for the request to create cluster entities */
export type CreateClusterEntitiesParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: CreateClusterEntitiesRequestBody;
};

/** Params to create a point cloud entity */
type PointCloudParams = Pick<CaptureTreePointCloudProperties, "type"> & {
  /** The ID of the point cloud, if it is being updated. */
  id?: GUID;

  /** The external ID of the point cloud, on the scanning device. */
  externalId?: string | null;

  /** The URI to the capture tree point cloud */
  uri: string | null;

  /** The md5 hash of the point cloud file */
  md5Hash: string | null;

  /** The file size of the capture tree point cloud */
  fileSize: number | null;

  /** The file name of the capture tree point cloud */
  fileName: string | null;
};

/**
 * @param data to check
 * @returns true if it matches the PointCloudParams type
 */
export function isPointCloudParams(data: unknown): data is PointCloudParams {
  return (
    validateNotNullishObject(data, "PointCloudParams") &&
    validatePrimitive(data, "id", "string", PropOptional) &&
    validatePrimitive(data, "externalId", "string", PropOptional) &&
    validatePrimitive(data, "uri", "string", PropOptional) &&
    validatePrimitive(data, "md5Hash", "string", PropOptional) &&
    validatePrimitive(data, "fileName", "string", PropOptional) &&
    validatePrimitive(data, "fileSize", "number", PropOptional)
  );
}

/** Params to create a scan entity */
export type CreateOrUpdateScanEntityParams = {
  /** The ID of the scan, if it is being updated. */
  id?: GUID;

  /** The pose of the entity */
  pose: CreateEntityPoseParam;

  /** The id of the parent entity */
  parentId?: GUID | null;

  /** The name of the entity */
  name: string;

  /** The type of the scan entity */
  type: CaptureTreeEntityType;

  /** An array of point cloud representations of this entity */
  pointClouds?: PointCloudParams[] | null;
};

/**
 * @param data to check
 * @returns true if it matches the CreateScanEntityParams type
 */
export function isCreateScanEntityParams(
  data: unknown,
): data is CreateOrUpdateScanEntityParams {
  return (
    validateNotNullishObject(data, "CreateScanEntityParams") &&
    validatePrimitive(data, "id", "string", PropOptional) &&
    isCreateEntityPoseParam(data.pose) &&
    validatePrimitive(data, "parentId", "string", PropOptional) &&
    validatePrimitive(data, "name", "string") &&
    validateEnumValue(data.type, CaptureTreeEntityType) &&
    validateArrayOf({
      object: data,
      prop: "pointClouds",
      elementGuard: isPointCloudParams,
      optionality: PropOptional,
    })
  );
}

/** Request body to create scan entities: array of scan entity params */
type CreateScanEntitiesRequestBody = CreateOrUpdateScanEntityParams[];

/**
 * @param data to check
 * @returns true if it matches the CreateScanEntitiesRequestBody type
 */
export function isCreateScanEntitiesRequestBody(
  data: unknown,
): data is CreateScanEntitiesRequestBody {
  const obj = { requestBody: data };

  return validateArrayOf({
    object: obj,
    prop: "requestBody",
    elementGuard: isCreateScanEntityParams,
  });
}

/** Params for the request to create scans entities */
export type CreateScanEntitiesParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: CreateScanEntitiesRequestBody;
};

/** Params for the request to create scans entities */
export type CreateOrUpdateRegistrationEdgesParams = {
  /** The ID of the registration revision to update */
  registrationRevisionId: GUID;

  /** Request body */
  requestBody: RegistrationEdge[];
};
