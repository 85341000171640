import {
  CompleteMeasurementEventProperties,
  DeleteMeasurementEventProperties,
  EventType,
  ToggleUnitOfMeasureActionSource,
} from "@/analytics/analytics-events";
import { useToggleUnitOfMeasure } from "@/components/common/unit-of-measure-context";
import { AnnotationVisibility } from "@/components/r3f/renderers/annotations/annotation-utils";
import { ExpandedMeasureRenderer } from "@/components/r3f/renderers/measurements/expanded-measure-renderer";
import { MultiPointMeasureRenderer } from "@/components/r3f/renderers/measurements/multi-point-measure-renderer";
import { TwoPointMeasureSegment } from "@/components/r3f/renderers/measurements/two-point-segment-renderer";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import {
  selectActiveMeasurement,
  selectAll3DMeasurements,
  selectIsMeasurementBeingTaken,
} from "@/store/measurement-tool-selector";
import {
  addMeasurement,
  ComponentsToDisplay,
  removeMeasurement,
  setActiveMeasurement,
  setIsMeasurementBeingTaken,
} from "@/store/measurement-tool-slice";
import { selectModeName } from "@/store/mode-selectors";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { deactivateTool, ToolName } from "@/store/ui/ui-slice";
import {
  setObjectVisibility,
  ViewObjectTypes,
} from "@/store/view-options/view-options-slice";
import { useUpdateVisibilityDistance } from "@/utils/use-update-visibility-distance";
import { Analytics } from "@faro-lotv/foreign-observers";
import { generateGUID } from "@faro-lotv/foundation";
import { usePerformanceMonitor } from "@react-three/drei";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { forwardRef, useCallback, useMemo, useRef, useState } from "react";
import { MOUSE, Vector3 } from "three";
import { ToolControlsRef } from "../tool-controls-interface";
import { MeasuresSorter } from "./measures-sorter";
import {
  MultiPointMeasureControls,
  MultiPointMeasureControlsActions,
} from "./multi-point-measures-controls";
import { ProjectionType } from "./multi-point-measures-controls-logic";

type MultiPointMeasuresProps = {
  /** The UUID of the project element that is being measured. */
  iElementIds: string[] | undefined;

  /**
   * Enable/disable controls
   *
   * @default true
   */
  enableControls?: boolean;
};

/**
 * @returns A component that enables the creation, editing, storage and deletion of two-point measures on a given model.
 */
export const MultiPointMeasures = forwardRef<
  ToolControlsRef,
  MultiPointMeasuresProps
>(function MultiPointMeasures(
  { iElementIds, enableControls = true }: MultiPointMeasuresProps,
  ref,
): JSX.Element {
  const projectionType = useRef(ProjectionType.DoNotProject);
  const dispatch = useAppDispatch();
  const activeMode = useAppSelector(selectModeName);

  const measurements = useAppSelector(selectAll3DMeasurements);

  const [points, setPoints] = useState<Vector3[]>();
  const [currentPoint, setCurrentPoint] = useState<Vector3>();
  const onCurrentPointChanged = useCallback((point: Vector3 | undefined) => {
    setCurrentPoint(point ? point.clone() : undefined);
  }, []);

  const onPointsChanged = useCallback(
    (points: Vector3[] | undefined) => {
      // In the line below we ensure that a new points array is set to the
      // state, that typically contains a new point. In this way, child components
      // like MultiSegmentRenderer react correctly to the addition/change of a point.
      setPoints(points ? [...points] : undefined);
      // Reset the current point to trigger a redraw of all segments
      setCurrentPoint(currentPoint?.clone());
    },
    [currentPoint],
  );

  const camera = useThree((s) => s.camera);
  const updateVisibilityDistance = useUpdateVisibilityDistance();
  const onMeasurementCompleted = useCallback(
    (isClosed: boolean, iElementId: string) => {
      if (!iElementIds?.includes(iElementId)) return;
      if (!points) return;

      Analytics.track<CompleteMeasurementEventProperties>(
        EventType.completeMeasurement,
        {
          isClosed,
          numberOfPoints: points.length,
        },
      );

      // Id of the new created measurement.
      const measurement = {
        id: generateGUID(),
        points: points.map((p) => p.toArray()),
        parentId: iElementId,
        metadata: {
          name: `measurement - ${measurements.length}`,
          isLoop: isClosed,
        },
        componentsToDisplay: ComponentsToDisplay.single3d,
      };
      dispatch(
        addMeasurement({
          elementID: iElementId,
          measurement,
        }),
      );

      setPoints(undefined);

      // Set the new measurement as the active one.
      dispatch(setActiveMeasurement(measurement));

      dispatch(setIsMeasurementBeingTaken(false));

      // Ensure the new measurement is visible
      updateVisibilityDistance(camera, points[0]);
      dispatch(
        setObjectVisibility({
          type: ViewObjectTypes.measurements,
          visibility: true,
        }),
      );
    },
    [
      camera,
      dispatch,
      iElementIds,
      measurements.length,
      points,
      updateVisibilityDistance,
    ],
  );

  const activeMeasurement = useAppSelector(selectActiveMeasurement);

  const isMeasurementBeingTaken = useAppSelector(selectIsMeasurementBeingTaken);

  const deleteActiveMeasurement = useCallback(() => {
    if (activeMeasurement) {
      Analytics.track<DeleteMeasurementEventProperties>(
        EventType.deleteMeasurement,
        {
          via: "delete key",
        },
      );

      dispatch(
        removeMeasurement({
          elementID: activeMeasurement.parentId,
          measurementID: activeMeasurement.id,
        }),
      );
    }
  }, [activeMeasurement, dispatch]);

  const sorter = useMemo(() => new MeasuresSorter(camera), [camera]);
  // Make sorter reactive to performance, with faster updates if possible
  const onIncline = useCallback(() => {
    sorter.secsBeforeUpdate = 2;
  }, [sorter]);
  const onDecline = useCallback(() => {
    sorter.secsBeforeUpdate = 0.1;
  }, [sorter]);
  usePerformanceMonitor({ onIncline, onDecline });

  // While picking the other measurements should not be interactable
  const disablePointerEvents = !!points;

  const { unitOfMeasure, toggleUnitOfMeasure } = useToggleUnitOfMeasure(
    true,
    ToggleUnitOfMeasureActionSource.measureToolbar,
  );

  const unselectActiveMeasurement = useCallback(() => {
    dispatch(setActiveMeasurement(undefined));
  }, [dispatch]);

  const deselectMeasureTool = useCallback(() => {
    if (!isMeasurementBeingTaken) dispatch(deactivateTool());
  }, [dispatch, isMeasurementBeingTaken]);

  const measureActive =
    useAppSelector(selectActiveTool) === ToolName.measurement;

  const onMeasurementStarted = useCallback(() => {
    unselectActiveMeasurement();

    dispatch(setIsMeasurementBeingTaken(true));
  }, [dispatch, unselectActiveMeasurement]);

  const controlActions = useRef<MultiPointMeasureControlsActions>(null);
  const onHandlerClicked = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      if (!points) return;
      if (index < 0 || index >= points.length) return;
      if (points.length <= 1) return;
      if (ev.button !== MOUSE.LEFT) return;
      if (projectionType.current !== ProjectionType.DoNotProject) return;

      if (index === 0) {
        controlActions.current?.completeMeasurement(true);
        ev.stopPropagation();
      } else if (index === points.length - 1) {
        controlActions.current?.completeMeasurement(false);
        ev.stopPropagation();
      }
    },
    [points],
  );

  const onHandlerContextMenu = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      controlActions.current?.deletePoint(index);
      ev.nativeEvent.preventDefault();
      ev.stopPropagation();
    },
    [],
  );

  const [isCurrentLabelVisible, setIsCurrentLabelVisible] = useState(true);
  const [isLastLabelVisible, setIsLastLabelVisible] = useState(true);
  const onHandlerHovered = useCallback(
    (index: number) => {
      if (points?.length === undefined) return;
      setIsCurrentLabelVisible(index !== points.length - 1);
      setIsLastLabelVisible(index !== 0);
    },
    [points],
  );

  const currentSegmentPosition = useMemo(() => {
    if (!points || points.length < 1 || !currentPoint) return;
    return Object.freeze(
      new Vector3()
        .addVectors(points[points.length - 1], currentPoint)
        .multiplyScalar(0.5),
    );
  }, [currentPoint, points]);

  const lastSegmentPosition = useMemo(() => {
    if (!points || points.length < 2 || !currentPoint) return;
    return Object.freeze(
      new Vector3().addVectors(points[0], currentPoint).multiplyScalar(0.5),
    );
  }, [currentPoint, points]);

  const labelContainer = useViewOverlayRef();

  return (
    <>
      {/** This is the violet dashed segment connecting the currently hovered point with the last point clicked */}
      {points &&
        points.length >= 1 &&
        currentPoint &&
        currentSegmentPosition && (
          <ExpandedMeasureRenderer
            position={currentSegmentPosition}
            firstPoint={points[points.length - 1]}
            secondPoint={currentPoint}
            live
            disablePointerEvents={disablePointerEvents}
            unitOfMeasure={unitOfMeasure}
            visible
            isToolActive
            isMainLabelVisible={isCurrentLabelVisible}
            areXYZLabelsVisible={false}
            labelContainer={labelContainer}
            dashed
            onToggleUnitOfMeasure={toggleUnitOfMeasure}
          />
        )}
      {/** This is the white dashed segment connecting the start point with the currently hovered point*/}
      {points &&
        points.length >= 2 &&
        currentPoint &&
        lastSegmentPosition &&
        isCurrentLabelVisible && (
          <TwoPointMeasureSegment
            visible
            start={currentPoint}
            end={points[0]}
            labelPosition={lastSegmentPosition}
            length={currentPoint.distanceTo(points[0])}
            index={0}
            main={false}
            live={false}
            isMeasurementActive
            isLabelActive
            labelContainer={labelContainer}
            unitOfMeasure={unitOfMeasure}
            onClick={() => {}}
            dashed
            labelsPointerEvents={disablePointerEvents ? "none" : "auto"}
            isLabelVisible={isLastLabelVisible}
          />
        )}
      {/** These are the handles and the segments rendering the already acquired polygon */}
      {points && (
        <MultiPointMeasureRenderer
          points={points}
          live
          isClosed={false}
          active
          disablePointerEvents={disablePointerEvents}
          visibility={AnnotationVisibility.Visible}
          unitOfMeasure={unitOfMeasure}
          onToggleUnitOfMeasure={toggleUnitOfMeasure}
          onHandlerClicked={onHandlerClicked}
          onHandlerContextMenu={onHandlerContextMenu}
          onHandlerHovered={onHandlerHovered}
        />
      )}
      {enableControls && (
        <MultiPointMeasureControls
          onPointsChanged={onPointsChanged}
          onCurrentPointChanged={onCurrentPointChanged}
          onMeasurementCompleted={onMeasurementCompleted}
          onDeleteActiveMeasurement={deleteActiveMeasurement}
          onMeasurementStarted={onMeasurementStarted}
          onEscPressed={() => {
            unselectActiveMeasurement();
            deselectMeasureTool();
          }}
          onMeasurementCanceled={() => {
            setPoints(undefined);
            // Deactivate the tool after the measurement is cancelled.
            dispatch(deactivateTool());
          }}
          onChangeProjectionType={(newProjectionType) => {
            projectionType.current = newProjectionType;
          }}
          ref={ref}
          actions={controlActions}
          active={measureActive}
          projectOnShiftKey={
            activeMode === "sheet"
              ? ProjectionType.ProjectOnXY
              : ProjectionType.ProjectOnHorizontalOrVertical
          }
        />
      )}
    </>
  );
});
